<template>
  <div id="app">
    <TheLayout />
  </div>
</template>
<style lang="scss">
@import './assets/styles/global';
</style>
<script>
import { detectMobile } from '@/utils'
export default {
  components: {
    TheLayout: () => import(/* webpackChunkName: "TheLayout" */ '@/containers/TheLayout')
  },
  async mounted() {
    this.checkPlatform()
  },
  methods: {
    checkPlatform() {
      if (detectMobile()) {
        this.$store.commit('common/setIsMobile', true)
      } else {
        this.$store.commit('common/setIsMobile', false)
      }
    }
  }
}
</script>
