const state = {
  isMobile: false
}
const actions = {}
const mutations = {
  setIsMobile(state, value) {
    state.isMobile = value
  }
}
const getters = {
  isMobile: (state) => state.isMobile
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
